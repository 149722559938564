.inputFieldWhiteOutline fieldset {
    border-color: #ffffff!important;
}
.errorFieldset, fieldset.errorFieldset {
    border: 1px solid #f44336;
    margin: 0 -1px -1px -1px;
    border-radius: 4px;
}
.cancelBtn span {
    color: #7b7d7d;
}
.loadingMaskContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loadingMaskContainer:focus {
    outline: none;
}

.datePickerItemFullWidth .react-datepicker-wrapper {
    width: 100%;
}

.datePickerItemFullWidth .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
}

.toggleButtons .actualityTypeButton > span {
    color: #29373d;
    text-transform: none;
}

.toggleButtons .actualityTypeButtonActive {
    font-weight: 600;
}
