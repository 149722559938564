

.cp_tree-table_header[style] {
    /* height: 50px !important; */
    border-bottom: 1px solid gray;
    /* text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1em;
    padding-left: 10px; */
}

/* .cp_tree-table_header-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
} */

.cp_tree-table_row[style] {
    /* height:50px !important; */
    border-bottom: 1px solid lightgrey;
    /* padding-left: 10px;
    padding-right: 10px; */
}

/* .cp_tree-table_viewport[style] {
    padding-bottom: 50px;
} */
