@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans);
.headerBar {}
.headerButton span {
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #29373d;
    text-transform: initial;
}
.formControlClass .mainSelectionField fieldset {
    border-color: #ffffff;
}


.cp_tree-table_header[style] {
    /* height: 50px !important; */
    border-bottom: 1px solid gray;
    /* text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1em;
    padding-left: 10px; */
}

/* .cp_tree-table_header-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
} */

.cp_tree-table_row[style] {
    /* height:50px !important; */
    border-bottom: 1px solid lightgrey;
    /* padding-left: 10px;
    padding-right: 10px; */
}

/* .cp_tree-table_viewport[style] {
    padding-bottom: 50px;
} */

.inputFieldWhiteOutline fieldset {
    border-color: #ffffff!important;
}
.errorFieldset, fieldset.errorFieldset {
    border: 1px solid #f44336;
    margin: 0 -1px -1px -1px;
    border-radius: 4px;
}
.cancelBtn span {
    color: #7b7d7d;
}
.loadingMaskContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loadingMaskContainer:focus {
    outline: none;
}

.datePickerItemFullWidth .react-datepicker-wrapper {
    width: 100%;
}

.datePickerItemFullWidth .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
}

.toggleButtons .actualityTypeButton > span {
    color: #29373d;
    text-transform: none;
}

.toggleButtons .actualityTypeButtonActive {
    font-weight: 600;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

